<template>
  <div
    class="carousel-container relative"
    @mouseleave="stopSliding"
    @mouseenter="startSliding"
  >
    <div
      class="carousel flex transition-transform duration-700"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div
        v-for="(image, index) in images"
        :key="index"
        class="carousel-item h-96 w-full bg-cover bg-center"
        :style="{ backgroundImage: 'url(' + image + ')' }"
      ></div>
    </div>
    <!-- Controls -->
    <button
      class="absolute left-0 top-1/2 z-10 m-3 -translate-y-1/2 transform bg-black bg-opacity-50 p-2 text-white"
      @click="prevImage"
    >
      &#10094;
    </button>
    <button
      class="absolute right-0 top-1/2 z-10 m-3 -translate-y-1/2 transform bg-black bg-opacity-50 p-2 text-white"
      @click="nextImage"
    >
      &#10095;
    </button>
  </div>
</template>

<script setup>
const images = ref([
  // Aquí van tus URLs de imágenes
  "/carousel/1.jpg",
  "/carousel/2.jpg",
  "/carousel/3.jpg",
]);

const currentIndex = ref(0);

const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
};

const prevImage = () => {
  currentIndex.value =
    (currentIndex.value - 1 + images.value.length) % images.value.length;
};

let interval;
const startSliding = () => {
  stopSliding(); // Limpiar el intervalo antes de iniciar uno nuevo
  interval = setInterval(nextImage, 5000); // Cambia imagen cada 3 segundos
};
const stopSliding = () => {
  clearInterval(interval);
};

startSliding(); // Inicia el carrusel automáticamente
</script>

<style scoped>
.carousel-container {
  overflow: hidden;
}
.carousel {
  width: 100%;
  display: flex;
}
.carousel-item {
  min-width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
